<template>
  <div>
    <div class="container" v-if="locale === 'zh_CN'">
      <img class="logo" src="../assets/images/logo.png" alt="block.cc" />
      <span class="text">登陆 <a href="https://pro.block.cc/">pro.block.cc</a> 以访问你的账户</span>
      <a href="https://pro.block.cc/"><button class="btn">前往 pro.block.cc</button></a>
    </div>
    <div class="container" v-else>
      <img class="logo" src="../assets/images/logo-en.png" alt="block.cc" />
      <span class="text">Log in to <a href="https://pro.mifengcha.com/">pro.mifengcha.com</a> to access your account</span>
      <a href="https://pro.mifengcha.com/"><button class="btn">Go to pro.mifengcha.com</button></a>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'redirect',
  computed: {
    ...mapGetters(['locale']),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';

.container {
  width: 600px;
  margin: 60px auto;
  // border: 1px solid #f1f1f1;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo {
    margin-top: 80px;
    height: 150px;
  }
  .text {
    margin-top: 40px;
    font-size: 24px;
    a {
      color: #007aff;
      &:hover {
        color: #007aff;
        cursor: pointer;
      }
    }
  }
  .btn {
    margin-top: 40px;
    background-color: #49494d;
    color: white;
    border: 1px solid #49494d;
    border-radius: 10px;
    padding: 15px 40px;
    font-size: 18px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
